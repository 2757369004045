import { render, staticRenderFns } from "./settings-page.vue?vue&type=template&id=bc87beb2&scoped=true&"
import script from "./settings-page.vue?vue&type=script&lang=js&"
export * from "./settings-page.vue?vue&type=script&lang=js&"
import style0 from "./settings-page.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./settings-page.vue?vue&type=style&index=1&id=bc87beb2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bc87beb2",
  null
  
)

export default component.exports
import {QSeparator,QCardSection,QSelect,QBtn,QIcon,QField} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QSeparator,QCardSection,QSelect,QBtn,QIcon,QField})
