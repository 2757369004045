//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import i18n from '@/vueI18n';

export default {
  name: 'app-settings-page',


  data() {
    return {
      language: '',
      notificationLanguage: '',
      languages: [
          'English',
          'Arabic'
      ]
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      is_screen_xs: 'layout/is_screen_xs',
      currentLanguageCode: 'layout/currentLanguageCode',
    }),
    // language(){
    //   return i18n.locale === 'en'? 'English': 'Arabic'
    // }
    // hasPermissionToCreate() {
    //   return new TripPermissions(this.currentUser).create;
    // },
  },
  mounted(){
    if (i18n.locale === 'en'){
      this.language='English'
      this.notificationLanguage='English'
    }
    else{
      this.language='Arabic'
      this.notificationLanguage='Arabic'

    }
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
    
  },
  watch:{
    language(){
      
      if(this.language == 'English'){
        i18n.locale = 'en'
      }
      else{
        i18n.locale = 'ar'
      }
    }
  }
};
